<template>
  <div class="tiles-wrapper">
    <DashboardSection>
      <template #top-right-button>
        <CustomButton
          iconClass="plus"
          label="Add roles"
          color="grey"
          size="small"
          @click="
            $router.push({
              name: studioId ? 'AddProjectRolesAdmin' : 'AddProjectRoles',
              params: studioId ? { projectId: projectId, studioId: studioId } : { id: projectId }
            })
          "
        />
        <CustomButton
          :iconVisible="false"
          label="Save budget"
          color="blue"
          size="small"
          v-if="toggleBudgetInput"
          @click="saveBudget"
        />
        <CustomButton iconClass="edit" label="Edit budget" color="grey" size="small" @click="editBudget" v-else />
      </template>
      <template #content>
        <TableTile>
          <template #tileContent>
            <div class="left-content">
              <div class="left-text">
                <p class="tile-title">{{ getTotalBudget.totalBudget }}</p>
                <p class="tile-subtitle grey-value">Budgeted hours</p>
              </div>
              <div class="left-text">
                <p class="tile-title">{{ formatCurrencies(getTotalBudget.totalBudgetValue) }}</p>
                <p class="tile-subtitle grey-value">Budget Values</p>
              </div>
              <div class="left-text">
                <p class="tile-title">{{ formatCurrencies(getTotalBudget.projectPrice) }}</p>
                <p class="tile-subtitle grey-value">Project Price</p>
              </div>
              <div class="left-text">
                <p class="tile-title">{{ getTotalBudget.profitePercentage }}%</p>
                <p class="tile-subtitle grey-value">Profit Recovery</p>
              </div>
            </div>
          </template>
        </TableTile>
        <div v-if="projectRoles && projectRoles.length > 0">
          <div class="grid-layout mobile-hide">
            <p class="left-heading-item">Role</p>
            <p class="left-heading-item">Rate(hour)</p>
            <p class="left-heading-item">Budgeted hours</p>
            <p class="left-heading-item">Team Member</p>
            <p class="left-heading-item"></p>
          </div>
          <TableTile v-for="role in projectRoles" :key="role?.id">
            <template #tileContent>
              <div v-if="role" class="grid-layout">
                <div class="span-4 order-1">{{ role ? role.name : '' }}</div>
                <div class="span-2 border">
                  <span class="desktop-hide">Rate</span>
                  {{ role ? formatCurrencies(role.rate) : '' }}
                </div>
                <div class="span-2">
                  <span class="desktop-hide">Budget</span>
                  <CustomInput v-if="toggleBudgetInput" v-model="role.estimatedHours" class="budget-price" />
                  <span v-else>{{ role ? role.estimatedHours : 0 }}</span>
                </div>
                <div class="span-4 order-2">
                  <div v-if="role?.selectedUsers">
                    <div v-for="user in role.selectedUsers[0]" :key="user">
                      <NameIcon :text="user.name" />
                      <p
                        v-if="
                          role.selectedUsers &&
                          role.selectedSubcontractors &&
                          role.selectedUsers[0].length + role.selectedSubcontractors[0].length <= 1
                        "
                      >
                        {{ user.name }}
                      </p>
                    </div>
                  </div>
                  <div v-if="role.selectedSubcontractors">
                    <div v-for="Subcontractor in role.selectedSubcontractors[0]" :key="Subcontractor">
                      <NameIcon :text="Subcontractor.name" />
                      <p
                        v-if="
                          role.selectedSubcontractors &&
                          role.selectedUsers &&
                          role.selectedUsers[0].length + role.selectedSubcontractors[0].length <= 1
                        "
                      >
                        {{ Subcontractor.name }}
                      </p>
                    </div>
                  </div>
                  <div
                    v-if="
                      role.selectedUsers &&
                      role.selectedUsers[0].length <= 0 &&
                      role.selectedSubcontractors &&
                      role.selectedSubcontractors[0].length <= 0
                    "
                  >
                    <p>Assign Team Member</p>
                  </div>
                </div>
              </div>
            </template>
            <template #menu>
              <ul v-if="role">
                <li
                  @click="
                    $router.push({
                      name: 'EditProjectRole',
                      params: { id: projectId, roleId: role.serviceId },
                      query: { type: role.id }
                    })
                  "
                >
                  Edit
                </li>
              </ul>
            </template>
          </TableTile>
        </div>
      </template>
    </DashboardSection>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import { ActionTypes as projectActions } from '@/store/projects/actions'
import CustomButton from '@/components/atom/CustomButton.vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import commonString from '@/utils/commonStrings'
import * as yup from 'yup'
import CustomInput from '@/components/atom/CustomInputModel.vue'
import authApi from '@/api/Authenticated'
import TableTile from '@/components/templates/TableTile.vue'
import { ActionTypes as UserActionTypes } from '@/store/users/actions'
import { ActionTypes as studioActions } from '@/store/studio/actions'
import NameIcon from '@/components/atom/NameIcon.vue'
import { ActionTypes } from '@/store/messages/actions'
import { MutationTypes } from '@/store/messages/mutations'
import commonFunctions from '@/utils/commonFunctions'
import { intlFormat } from '@/utils/currencyFunctions'
import { serviceTypes } from '@shared/*'
export default defineComponent({
  name: 'Team',
  components: {
    CustomButton,
    DashboardSection,
    CustomInput,
    TableTile,
    NameIcon
  },

  setup() {
    type ProjectRoles = {
      id: number
      name: string
      rate: number
      estimatedHours: number
      serviceId: number
      selectedUsers: {
        userId: number
        name: string
      }[]
      selectedSubcontractors: {
        subcontractorId: number
        name: string
      }[]
      user: []
      subcontractor: []
    }
    const store = useStore()
    const route = useRoute()
    const toggleBudgetInput = ref(false)
    const rateValue = ref()
    const budgetValue = ref()
    const projectServiceId = ref<number>(0)
    const teamBudget = ref(0)
    const teamBudgetValue = ref(0)
    const projectId = +route.params.id
    const studioId = +route.params.studioId
    // Fetch all the roles
    const getRoles = ref<serviceTypes.ServiceResponse[]>([])
    onMounted(async () => {
      store.dispatch(projectActions.GET_PROJECT_TEAM, projectId)
      store.dispatch(UserActionTypes.GET_USERS)
      store.dispatch(studioActions.GET_SUBCONTRACTORS)
      getRoles.value = await authApi.getProjectRoles()
    })

    // Fetch subcontractors list
    const subcontractorsList = computed(() => {
      return store.state.studio.subContractors.map((subcontractor) => {
        if (subcontractor) {
          return { name: subcontractor.name, value: subcontractor.id + 'sb' }
        }
      })
    })

    // Fetch users list
    const userList = computed(() => {
      return store.state.user.users.map((user) => {
        if (user) {
          return { name: user.fullName, value: user.id + 'u' }
        }
      })
    })

    // Combine the user and subcontractor list
    const teammemberList = [...userList.value, ...subcontractorsList.value]

    // Users selected for a particular role
    const selectedUserListPerService = (serviceId: number) => {
      return store.state.project.projectTeam?.ProjectService.map((team: any) => {
        if (team.serviceId === serviceId) {
          if (team.user) {
            return team.user.map((user: any) => {
              return { userId: user.id, name: user.fullName }
            })
          }
        }
      }).filter((x: any) => x !== undefined)
    }
    const selectedSubcontractorListPerService = (serviceId: number) => {
      return store.state.project.projectTeam?.ProjectService.map((team: any) => {
        if (team.serviceId === serviceId) {
          if (team.subcontractor) {
            return team.subcontractor.map((subcontractor: any) => {
              return { subcontractorId: subcontractor.id, name: subcontractor.name }
            })
          }
        }
      }).filter((x: any) => x !== undefined)
    }

    // Fetch project detail
    const projectTeam = computed(() => store.state.project.projectTeam)

    // Fetch the Day Rate Value
    const getRateAndBudgetPerService = (serviceId: number) => {
      return store.state.project.projectTeam?.ProjectService.map((team: any) => {
        if (team.serviceId === serviceId) {
          rateValue.value = team.rate
          budgetValue.value = team.estimatedHours
          projectServiceId.value = team.id
        }
      }).filter((x: any) => x !== undefined)
    }

    // Filter roles according to project
    const projectRoles = computed(() => {
      return getRoles.value
        .filter((array) =>
          store.state.project.projectTeam?.ProjectService.some((filter: any) => !(filter.serviceId !== array.id))
        )
        .map((service: any) => {
          if (service) {
            getRateAndBudgetPerService(service.id)
            return {
              id: projectServiceId.value,
              name: service.name,
              rate: rateValue.value ? rateValue.value : 0,
              estimatedHours: budgetValue.value ? budgetValue.value : 0,
              serviceId: service.id,
              selectedUsers: selectedUserListPerService(service.id),
              selectedSubcontractors: selectedSubcontractorListPerService(service.id),
              user: [],
              subcontractor: []
            }
          }
        })
        .filter((x: any) => x !== undefined)
    })

    // Fetch the teammember budget
    const teamMemberBudget = () => {
      teamBudget.value = 0
      teamBudgetValue.value = 0
      projectRoles.value.map((team: any) => {
        teamBudget.value += Number(team.estimatedHours)
        teamBudgetValue.value += Number(team.rate * team.estimatedHours)
      })
    }

    teamMemberBudget()
    // fetch the total budget value and profite
    const getTotalBudget = computed(() => {
      const projectPrice = projectTeam.value && projectTeam.value.totalValue ? projectTeam.value.totalValue : 0
      let profitePercentage = 0
      if (teamBudgetValue.value > 0) {
        profitePercentage = (projectPrice / teamBudgetValue.value) * 100
        if (profitePercentage > 1000) {
          profitePercentage = profitePercentage / 100
        }
      }
      return {
        totalBudget: teamBudget.value,
        totalBudgetValue: teamBudgetValue.value,
        projectPrice: projectPrice,
        profitePercentage: profitePercentage > 0 ? Math.round(profitePercentage) : 0
      }
    })
    // Submit the new changes
    async function saveBudget() {
      if (projectRoles.value) {
        const budgetValue = projectRoles.value.map((data: any) => {
          if (data.selectedUsers[0] && data.selectedUsers[0].length > 0) {
            data.selectedUsers[0].map((user: any) => {
              data.user.push({ id: user.userId })
            })
          }
          if (data.selectedSubcontractors[0] && data.selectedSubcontractors[0].length > 0) {
            data.selectedSubcontractors[0].map((subcontractor: any) => {
              data.subcontractor.push({ id: subcontractor.subcontractorId })
            })
          }
          return {
            id: data.id,
            serviceId: data.serviceId,
            estimatedHours: +data.estimatedHours,
            rate: +data.rate,
            user: data.user,
            subcontractor: data.subcontractor
          }
        })
        const res = await authApi.updateProjectService(+projectId, budgetValue as Array<any>)
        if (res) {
          teamMemberBudget()
          toggleBudgetInput.value = false
          store.dispatch(MutationTypes.SET_TOASTER_MESSAGE, 'Budget updated successfully')
          store.dispatch(ActionTypes.SET_TOASTER_TYPE, 'success')
          store.dispatch(ActionTypes.SHOW_TOASTER_MESSAGE, true)
        }
      }
    }
    const iconText = function (name: string) {
      return name.match(/\b(\w)/g)?.join('')
    }
    const schema = yup.object({
      rate: yup.number()
    })
    async function editBudget() {
      toggleBudgetInput.value = true
    }
    const formatCurrencies = (value: number) => intlFormat(value)
    return {
      projectId,
      iconText,
      schema,
      commonString,
      editBudget,
      toggleBudgetInput,
      getTotalBudget,
      projectRoles,
      saveBudget,
      teammemberList,
      formatCurrencies,
      studioId,
      commonFunctions
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.add-button
  display: flex
  justify-content: flex-end
  & > *
    max-width: 12rem
.grey-value
  color: map-get($greyShades,greyShade60)
  font-size: 0.875rem
.table-tile .left-content .left-text
  text-align: center
.table-tile > *
  padding: 1rem
.table-tile .tile-title
  font-size: 2rem
  @media( max-width: $md-breakpoint)
    font-size: 1rem
    padding-right: 0.3rem
.table-tile .tile-subtitle
  @media( max-width: $md-breakpoint)
    padding: 0.2rem
.memberTags
  display: flex
  flex-wrap: wrap
.left-content
  display: grid
  grid-gap: 1rem
  grid-template-columns: repeat(4,1fr)
  @media( max-width: $md-breakpoint)
    grid-template-columns: repeat(2,1fr)
.desktop-hide
  display: none
@media( max-width: $lg-breakpoint)
  .grid-layout.mobile-hide
    display: none
  .desktop-hide
    display: block
    text-align: left
    padding: 0
  .span-4
    grid-column: span 4/ auto
  .span-2
    grid-column: span 2/ auto
  .order-1
    order: -3
    margin: 1rem 0
    font-size: 1.5rem
    font-weight: bold
  .order-2
    order: -2
    margin: 1rem 0
  .budget-price .input-field .default-input
    padding-right: 0rem
.left-content > *
  text-align: left
.left-heading-item
  text-align: left
  margin-top: 2rem
  font-weight: bold
  color: black
  &:first-child
    padding-left: 1rem
.left-text
  text-align: left
.selected-users
  background: #10b981
  display: flex
  align-items: center
  width: max-content
  white-space: nowrap
  padding: 5px 8px
  border-radius: 22px
  margin: 0.4rem 1rem 0.4rem 0
  color: #fff
.multiselect-tag-remove
  display: flex
  align-items: center
  justify-content: center
  margin-left: 1rem
  border-radius: 4px
  cursor: pointer
.grid-layout
  display: grid
  grid-template-columns: repeat(4,1fr)
  width: 100%
  padding-right: 5rem
  grid-gap: 1rem
.span-4.order-2
  display: flex
  flex-direction: row
.span-4.order-2 div
  display: flex
  flex-direction: row
</style>
