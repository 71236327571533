
import { defineComponent, computed, ref, onMounted } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import { ActionTypes as projectActions } from '@/store/projects/actions'
import CustomButton from '@/components/atom/CustomButton.vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import commonString from '@/utils/commonStrings'
import * as yup from 'yup'
import CustomInput from '@/components/atom/CustomInputModel.vue'
import authApi from '@/api/Authenticated'
import TableTile from '@/components/templates/TableTile.vue'
import { ActionTypes as UserActionTypes } from '@/store/users/actions'
import { ActionTypes as studioActions } from '@/store/studio/actions'
import NameIcon from '@/components/atom/NameIcon.vue'
import { ActionTypes } from '@/store/messages/actions'
import { MutationTypes } from '@/store/messages/mutations'
import commonFunctions from '@/utils/commonFunctions'
import { intlFormat } from '@/utils/currencyFunctions'
import { serviceTypes } from '@shared/*'
export default defineComponent({
  name: 'Team',
  components: {
    CustomButton,
    DashboardSection,
    CustomInput,
    TableTile,
    NameIcon
  },

  setup() {
    type ProjectRoles = {
      id: number
      name: string
      rate: number
      estimatedHours: number
      serviceId: number
      selectedUsers: {
        userId: number
        name: string
      }[]
      selectedSubcontractors: {
        subcontractorId: number
        name: string
      }[]
      user: []
      subcontractor: []
    }
    const store = useStore()
    const route = useRoute()
    const toggleBudgetInput = ref(false)
    const rateValue = ref()
    const budgetValue = ref()
    const projectServiceId = ref<number>(0)
    const teamBudget = ref(0)
    const teamBudgetValue = ref(0)
    const projectId = +route.params.id
    const studioId = +route.params.studioId
    // Fetch all the roles
    const getRoles = ref<serviceTypes.ServiceResponse[]>([])
    onMounted(async () => {
      store.dispatch(projectActions.GET_PROJECT_TEAM, projectId)
      store.dispatch(UserActionTypes.GET_USERS)
      store.dispatch(studioActions.GET_SUBCONTRACTORS)
      getRoles.value = await authApi.getProjectRoles()
    })

    // Fetch subcontractors list
    const subcontractorsList = computed(() => {
      return store.state.studio.subContractors.map((subcontractor) => {
        if (subcontractor) {
          return { name: subcontractor.name, value: subcontractor.id + 'sb' }
        }
      })
    })

    // Fetch users list
    const userList = computed(() => {
      return store.state.user.users.map((user) => {
        if (user) {
          return { name: user.fullName, value: user.id + 'u' }
        }
      })
    })

    // Combine the user and subcontractor list
    const teammemberList = [...userList.value, ...subcontractorsList.value]

    // Users selected for a particular role
    const selectedUserListPerService = (serviceId: number) => {
      return store.state.project.projectTeam?.ProjectService.map((team: any) => {
        if (team.serviceId === serviceId) {
          if (team.user) {
            return team.user.map((user: any) => {
              return { userId: user.id, name: user.fullName }
            })
          }
        }
      }).filter((x: any) => x !== undefined)
    }
    const selectedSubcontractorListPerService = (serviceId: number) => {
      return store.state.project.projectTeam?.ProjectService.map((team: any) => {
        if (team.serviceId === serviceId) {
          if (team.subcontractor) {
            return team.subcontractor.map((subcontractor: any) => {
              return { subcontractorId: subcontractor.id, name: subcontractor.name }
            })
          }
        }
      }).filter((x: any) => x !== undefined)
    }

    // Fetch project detail
    const projectTeam = computed(() => store.state.project.projectTeam)

    // Fetch the Day Rate Value
    const getRateAndBudgetPerService = (serviceId: number) => {
      return store.state.project.projectTeam?.ProjectService.map((team: any) => {
        if (team.serviceId === serviceId) {
          rateValue.value = team.rate
          budgetValue.value = team.estimatedHours
          projectServiceId.value = team.id
        }
      }).filter((x: any) => x !== undefined)
    }

    // Filter roles according to project
    const projectRoles = computed(() => {
      return getRoles.value
        .filter((array) =>
          store.state.project.projectTeam?.ProjectService.some((filter: any) => !(filter.serviceId !== array.id))
        )
        .map((service: any) => {
          if (service) {
            getRateAndBudgetPerService(service.id)
            return {
              id: projectServiceId.value,
              name: service.name,
              rate: rateValue.value ? rateValue.value : 0,
              estimatedHours: budgetValue.value ? budgetValue.value : 0,
              serviceId: service.id,
              selectedUsers: selectedUserListPerService(service.id),
              selectedSubcontractors: selectedSubcontractorListPerService(service.id),
              user: [],
              subcontractor: []
            }
          }
        })
        .filter((x: any) => x !== undefined)
    })

    // Fetch the teammember budget
    const teamMemberBudget = () => {
      teamBudget.value = 0
      teamBudgetValue.value = 0
      projectRoles.value.map((team: any) => {
        teamBudget.value += Number(team.estimatedHours)
        teamBudgetValue.value += Number(team.rate * team.estimatedHours)
      })
    }

    teamMemberBudget()
    // fetch the total budget value and profite
    const getTotalBudget = computed(() => {
      const projectPrice = projectTeam.value && projectTeam.value.totalValue ? projectTeam.value.totalValue : 0
      let profitePercentage = 0
      if (teamBudgetValue.value > 0) {
        profitePercentage = (projectPrice / teamBudgetValue.value) * 100
        if (profitePercentage > 1000) {
          profitePercentage = profitePercentage / 100
        }
      }
      return {
        totalBudget: teamBudget.value,
        totalBudgetValue: teamBudgetValue.value,
        projectPrice: projectPrice,
        profitePercentage: profitePercentage > 0 ? Math.round(profitePercentage) : 0
      }
    })
    // Submit the new changes
    async function saveBudget() {
      if (projectRoles.value) {
        const budgetValue = projectRoles.value.map((data: any) => {
          if (data.selectedUsers[0] && data.selectedUsers[0].length > 0) {
            data.selectedUsers[0].map((user: any) => {
              data.user.push({ id: user.userId })
            })
          }
          if (data.selectedSubcontractors[0] && data.selectedSubcontractors[0].length > 0) {
            data.selectedSubcontractors[0].map((subcontractor: any) => {
              data.subcontractor.push({ id: subcontractor.subcontractorId })
            })
          }
          return {
            id: data.id,
            serviceId: data.serviceId,
            estimatedHours: +data.estimatedHours,
            rate: +data.rate,
            user: data.user,
            subcontractor: data.subcontractor
          }
        })
        const res = await authApi.updateProjectService(+projectId, budgetValue as Array<any>)
        if (res) {
          teamMemberBudget()
          toggleBudgetInput.value = false
          store.dispatch(MutationTypes.SET_TOASTER_MESSAGE, 'Budget updated successfully')
          store.dispatch(ActionTypes.SET_TOASTER_TYPE, 'success')
          store.dispatch(ActionTypes.SHOW_TOASTER_MESSAGE, true)
        }
      }
    }
    const iconText = function (name: string) {
      return name.match(/\b(\w)/g)?.join('')
    }
    const schema = yup.object({
      rate: yup.number()
    })
    async function editBudget() {
      toggleBudgetInput.value = true
    }
    const formatCurrencies = (value: number) => intlFormat(value)
    return {
      projectId,
      iconText,
      schema,
      commonString,
      editBudget,
      toggleBudgetInput,
      getTotalBudget,
      projectRoles,
      saveBudget,
      teammemberList,
      formatCurrencies,
      studioId,
      commonFunctions
    }
  }
})
